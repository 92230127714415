import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => {
  const menuItems = [
    {
      name: "Blog",
      to: "/",
    },
    {
      name: "About",
      to: "/about",
    },
    {
      name: "Contact",
      to: "/contact",
    },
  ]

  const menuViews = menuItems
    .map(el => (
      <Link to={el.to} key={el.to} activeClassName="menu-item-active">
        {el.name}
      </Link>
    ))
    .reduce((left, right) => [left, <span> - </span>, right])

  return (
    <header
      style={{
        marginBottom: `1.45rem`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem  1.0875rem 0.5rem 1.0875rem`,
        }}
      >
        <h1 style={{ margin: "0 0 0.25rem 0 " }}>
          <Link to="/">{siteTitle}</Link>
        </h1>
      </div>
      <div style={{}}>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0 1.0875rem 0 1.0875rem`,
          }}
        >
          {menuViews}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
