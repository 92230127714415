/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { SocialIcon } from "react-social-icons"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const socialIconsStyle = {
    width: 32,
    height: 32,
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        className="content"
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `2rem 1.5rem`,
          background: `#FFFFFF`,
        }}
      >
        <main>{children}</main>
        <footer style={{ padding: `2rem 0 0 0` }}>
          <SocialIcon
            style={socialIconsStyle}
            url="http://twitter.com/gekrabbel"
          />
          <SocialIcon
            style={socialIconsStyle}
            url="https://www.linkedin.com/in/arnestockmans/"
          />
          © {new Date().getFullYear()} Arne Stockmans, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
